import $store from '@/store'

const { id: userId, group, roleId } = $store.getters['auth/user'];
const isMe = (id) => userId === id;
const isOrganization = group === 'organization'
const isViewer = [4, 7].includes(roleId)
  
export {
  isMe as isMe,
  isOrganization as isOrganization,
  isViewer as isViewer
}