<template lang="pug">
.confirm-dialog
  v-dialog(
    max-width="600"
    persistent
    v-model="isOpen"
  )
    v-card
      v-card-title.bg-white.justify-between.border-b
        div {{ title }}
        v-spacer
        div
          v-btn(
            fab
            small
            depressed
            @click="$emit('close', false)"
          )
            v-icon mdi-close

      v-card-text.pa-8.py-10
        .d-flex 
          template( v-if="type === 'warning'" )
            .flex-shrink
              svg.text-red-600.mr-4.h-8.w-8(xmlns='http://www.w3.org/2000/svg', fill='none', viewbox='0 0 32 32', stroke='currentColor')
                path(stroke-linecap='round', stroke-linejoin='round', stroke-width='2', d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z')
            .flex-grow.text-base
              | {{ text }}
          template( v-if="type === 'info'" )
            .flex-shrink
              svg.text-blue-400.mr-4.h-6.w-6(xmlns='http://www.w3.org/2000/svg', fill='none', viewbox='0 0 24 24', stroke='currentColor')
                path(stroke-linecap='round', stroke-linejoin='round', stroke-width='2', d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z')
            .flex-grow.text-base
              | {{ text }}


      v-card-actions( right ).bg-gray-100
        v-btn(
          depressed
          @click="$emit('close', false)"
        ) Cancel 
        v-btn(
          depressed
          @click="$emit('close', { run: true, data })"
          :color="btnColor"
        ) {{ actionText }}

</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'ConfirmDialog',

  props: {
    actionText: String,
    title: String,
    isOpen: Boolean,
    text: String,
    type: String,
    data: Object
  },

  setup(props) {
    const btnColor = computed(() => {
      switch (props.type) {
        case 'warning': return 'secondary'
        default: return 'primary'
      }
    })
    return { btnColor } 
  }
}
</script>

<style>

</style>