<template lang="pug">
.user-list
  .d-flex.justify-between.align-center
    h3 Users 
    div
      v-btn(
        color="primary"
        v-if="$can('create', 'users')"
        depressed
        @click="isAdminUserCreateEditOpen = true"
      ) + New Admin

  .d-flex.justify-between.mt-16.gap-8
    div.flex-1
      v-text-field(
        class="mb-4"
        v-model="search"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        outlined
        dense
        clearable
      )

    .flex-1
      v-select(
        v-model="status"
        outlined
        multiple
        dense
        :items="statuses"
      )

  v-data-table.border.shadow(
    must-sort
    :items="users"
    :loading="isPending"
    :server-items-length="!isPending && pagination ? pagination.total : 0"
    :headers="headers"
    :options.sync="options"
  )
    template(
      v-slot:item.status="{ item: user }"
    )
      v-chip( 
        :color="`${ user.statusColor } dark-3`"
        :text-color="`${ user.statusColor } lighten-5`"
        small 
      ) 
        span {{ user.status }}
    template(
      v-slot:item.actions="{ item: user }"
    )
      v-menu( left  offset-y )
        template( v-slot:activator="{ on, attrs }" )
          v-btn(
            color="gray"
            v-bind="attrs"
            v-on="on"
            depressed
            small
            fab
          )
            v-icon mdi-dots-vertical
        v-list.divide-y
          v-list-item( 
            class="hover:bg-gray-200 cursor-pointer"
            @click="openDialog(user, 'adminUser')"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-square-edit-outline
            v-list-item-content
              v-list-item-title.cursor-pointer Edit

          v-list-item( 
            v-if="user.status === 'active' && !isMe(user.id)"
            class="hover:bg-gray-200 cursor-pointer"
            @click="openDialog(user, 'confirmDeactivate')"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-account-cancel
            v-list-item-content
              v-list-item-title.cursor-pointer Deactivate

          v-list-item( 
            v-if="user.status === 'inactive' && !isMe(user.id)"
            class="hover:bg-gray-200 cursor-pointer"
            @click="openDialog(user, 'confirmActivate')"
          )
            v-list-item-icon
              v-icon( color="primary" ) mdi-account-reactivate
            v-list-item-content
              v-list-item-title.cursor-pointer Activate

  AdminUserCreateEditDialog(
    :isOpen="isAdminUserCreateEditOpen"
    @close="closeAndRefresh"
    :editingUser="editingUser"
    v-if="isAdminUserCreateEditOpen"
  )

  ConfirmDialog(
    :title="confirmContext.title"
    :text="confirmContext.text"
    :actionText="confirmContext.actionText"
    :type="confirmContext.type"
    :isOpen="isConfirmDialogOpen"
    @close="closeAndMaybeUpdateUser"
    v-if="isConfirmDialogOpen"
  )

</template>

<script>
import { ref, computed, reactive } from '@vue/composition-api'
import AdminUserCreateEditDialog from '@/views/users/Admin.CreateEdit.Dialog'
import ConfirmDialog from '@/components/Dialogs/Confirm.Dialog'
import useDataTableFind from '@/helpers/useDataTableFind'
import dataTableHeaders from '@/helpers/dataTableHeaders'
import { isMe } from '@/helpers/identityHelper'

export default {
  name: 'AdminsList',

  components: {
    AdminUserCreateEditDialog,
    ConfirmDialog
  },

  methods: {
    openDialog(data, dialog) {
      switch (dialog) {
        case 'adminUser': {
          this.isAdminUserCreateEditOpen = true
          this.editingUser = data
          break
        }
        case 'confirmDeactivate': {
          this.editingUser = data
          this.confirmContext = {
            title: "Deactivate User",
            text: `Are you sure you want to deactivate ${ this.editingUser.fullName }?`,
            actionText: "Deactivate",
            type: "warning"
          }

          this.editingUser = null
          this.isConfirmDialogOpen = true
          break
        }
        case 'confirmActivate': {
          this.editingUser = data
          this.confirmContext = {
            title: "Activate User",
            text: `Are you sure you want to activate ${ this.editingUser.fullName }?`,
            actionText: "Activate",
            type: "info"
          }

          this.editingUser = null
          this.isConfirmDialogOpen = true
          break
        }
        default: {
          console.error('Invalid method name')
          break;
        }
      }

    },
    closeAndRefresh(refresh) {
      if (refresh) this.findUsers()
      this.isAdminUserCreateEditOpen = false;
      this.editingUser = null;
    },
    async closeAndMaybeUpdateUser(shouldDeactivate) {
      if (shouldDeactivate) {
        const { id, status } = this.editingUser
        const newStatus = status === 'inactive' ? 'active' : 'inactive'
        const { User } = this.$FeathersVuex.api
        const newUser = new User({ id, status: newStatus })
        
        try {
          await newUser.update()
          const statusAction = status === 'inactive' ? 'activated' : 'deactivated';
          this.$snackSuccess(`Successfully ${ statusAction } ${ this.editingUser.firstName }`)
        } catch(e) {
          this.$snackError(e)
        }
      }
      
      this.isConfirmDialogOpen = false
    }
  },

  setup() {
    const editingUser = ref(null)
    const confirmContext = reactive({})
    const isAdminUserCreateEditOpen = ref(false)
    const isConfirmDialogOpen = ref(false)

    const status = ref(['active', 'inactive'])
    const statuses = ref([
      {
        value: 'active',
        text: 'Active'
      }, {
        value: 'inactive',
        text: 'Inactive'
      } 
    ]);
    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'FirstName',
          value: 'firstName',
          filterable: true
        },
        {
          text: 'LastName',
          value: 'lastName',
          filterable: true
        },
        {
          text: 'Email',
          value: 'email',
          filterable: true
        },
        {
          text: 'Status',
          value: 'status'
        }
      ], 
      [],
      true
    );
    
    const filter = computed(() => {
      return { 
        $joinEager: '[role, organization]',
        status: { $in: status.value },
        roleId: { $in: [1,2] }
      }
    });
    const { 
      items: users, 
      isPending, 
      pagination, 
      findItems: findUsers,
      options,
      search
    } = useDataTableFind(
      'users',
      filterableColumns,
      filter,
      { sortBy: ['firstName'] }
    )

    return {
      editingUser,
      users,
      isPending,
      headers,
      findUsers,
      options,
      pagination,
      confirmContext,
      status,
      statuses,
      search,

      isAdminUserCreateEditOpen,
      isConfirmDialogOpen,

      isMe
    }
  }
}
</script>

<style>

</style>